<template>
  <div style="background: #ffffff" class="pb-5" id="videoSection">
    <v-container>
      <v-row>
        <v-col>
          <div class="heading">
            Wizfit Challenge Journey
            <div class="hLine"></div>
          </div>
          <br />
          <div class="tasks-container">
            <!-- Loop through each watched_summary category -->
            <button
              v-for="(progress, category) in taskProgress.watched_summary"
              :key="category"
              class="task-box"
            >
              <img
                src="@/assets/thingsToDo/tsk_track.png"
                alt="task"
                width="20px"
                height="20px"
              />
              <span>{{ progress.progress }}</span>
            </button>
            <!-- Additional buttons for task progress -->
            <button class="task-box">
              <img
                src="@/assets/thingsToDo/tsk_track.png"
                alt="distinct_shares"
                width="20px"
                height="20px"
              />
              <span>{{ taskProgress.distinct_shares.progress }}</span>
            </button>
            <button class="task-box">
              <img
                src="@/assets/thingsToDo/tsk_track.png"
                alt="five_day_watch"
                width="20px"
                height="20px"
              />
              <span>{{ taskProgress.five_day_watch.progress }}</span>
            </button>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="8" class="text-center">
          <v-btn color="#38227a" dark @click="toggleVideoBadgesModal({ show: true })">
            Create Playlist
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <div class="heading" style="margin-top: 2%;">
            Earned Badges
            <div class="hLine"></div>
          </div>
          <div class="badge-container">
            <div class="header">{{ this.studentName }} Badge Case</div>

            <!-- Top Row of Badges -->
            <div class="badge-row">
              <div class="badge">
                <img :src="badgesList[4].image" alt="badge1" />
              </div>
              <div class="badge">
                <img :src="badgesList[1].image" alt="badge2" />
              </div>
              <div class="badge">
                <img :src="badgesList[2].image" alt="badge3" />
              </div>
            </div>

            <!-- Bottom Row of Badges -->
            <div class="badge-row">
              <div class="badge">
                <img :src="badgesList[5].image" alt="badge-share" />
                <div class="badge-labelday">
                  <span class="badge-number">{{ badgesList[5].count }}</span>
                </div>
              </div>
              <div class="badge">
                <img :src="badgesList[0].image" alt="badge4" />
              </div>
              <div class="badge">
                <img :src="badgesList[3].image" alt="badge5" />
              </div>
              <div class="badge">
                <img :src="badgesList[6].image" alt="badge-day" />
                <div class="badge-label">
                  <span class="badge-number">{{ badgesList[6].count }}</span>
                </div>
              </div>
            </div>

            <!-- Shelf -->
            <div class="group-image-container">
              <img
                src="@/assets/thingsToDo/Group1.png"
                alt="Group"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <video-player-modal></video-player-modal>
    <video-badges-modal></video-badges-modal>
  </div>
</template>


<script>
import {
  API_GET_STUDENT_VIDEO_PROGRESS,
  API_STUDENT_TASK_TRACK,
  API_STUDENT_BADGES,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { mapActions } from "vuex";
export default {
  name: "VideoSection",
  components: {
    VideoPlayerModal: () => import("./VideoSectionViewer/VideoSectionViewer"),
    VideoBadgesModal: () =>
      import("@/views/CampaignManagement/VideoBadgesModal"),
  },
  data() {
    return {
      student: null,
      studentName: "",
      taskProgress: {},
      videoList: [],
      badgesList: [],
      videoToPlay: {},
      btnSelector: true,
      videoViewer: {
        flag: false,
        src: "",
        type: "video",
        obj: {},
      },
    };
  },
  created() {
    this.$root.$refs.videoSection = this;
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions({
      toggleVideoModal: "videoSection/toggleModal",
      toggleVideoBadgesModal: "campaign/toggleBadgesModal",
    }),
    mouseLeave() {
      setTimeout(() => {
        return false;
      }, 5000);
    },
    playVideo() {
      // this.videoViewer.src = this.videoToPlay.video_url;
      // this.videoViewer.obj = this.videoToPlay;
      // this.videoViewer.flag = true;
      this.toggleVideoModal({
        show: true,
        obj: this.videoToPlay,
        src: this.videoToPlay.video_url,
      });
    },
    selectVideo(item, watch) {
      if (item.is_next_video) {
        console.log(item, watch);
        this.videoToPlay = item;
        if (watch) {
          this.playVideo();
        }
      }
    },
    getThumbnail(item) {
      if (item.is_next_video) {
        return item.active_thumbnail;
      } else {
        return item.thumbnail;
      }
    },
    watchagain(item) {
      // console.log(item, "watch again");
      // this.videoViewer.src = item.video_url;
      // this.videoViewer.flag = true;
      this.toggleVideoModal({
        show: true,
        obj: item,
        src: item.video_url,
      });
    },
    getDetails() {
      const successHandler = (res) => {
        console.log(res.data, "video");
        this.videoToPlay = res.data.next_video_to_play;
        this.videoList = res.data.video_watch_list;
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["student_id"] = this.student;
      Axios.request_GET(
        API_GET_STUDENT_VIDEO_PROGRESS,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getStudentTaskTrack() {
      const successHandler = (res) => {
        this.taskProgress = res.data.task_progress;
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["student_id"] = this.student;
      Axios.request_GET(
        API_STUDENT_TASK_TRACK,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getStudentBadges() {
      const successHandler = (res) => {
        this.studentName = res.data.student_name;
        this.badgesList = res.data.badges;
        console.log("dddddddddd", this.badgesList[0].image);
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["student_id"] = this.student;
      Axios.request_GET(
        API_STUDENT_BADGES,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    postVideoComplete(obj, watchTime) {
    alert("postVideoComplete")
      const successHandler = (res) => {
        console.log(res.data);
        this.videoToPlay = res.data.next_video_to_play;
        this.videoList = res.data.video_watch_list;
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
        alert("obj.id"+this.videoToPlay.id)
      formData["video"] = this.videoToPlay.id;
      formData["student"] = this.$route.query.student;
      formData["watch_time"] = watchTime;
      formData["is_video_watched"] = true;

      Axios.request_POST(
        API_GET_STUDENT_VIDEO_PROGRESS,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    loadNextVideo() {
  const nextVideo = this.videoList.find(video => video.is_next_video);
  
  if (nextVideo) {
    this.videoToPlay = nextVideo;
    
    // Load and play the next video automatically
    const player = this.$refs.videoPlayer;  // Assuming a ref for the video player
    player.src({ type: "video/mp4", src: nextVideo.video_url });
    player.play();
  } else {
    console.log("No more videos to play.");
  }
}
  },
  mounted() {
    if (this.$route.query.student) {
      this.student = this.$route.query.student;
    }
    this.getDetails();
    this.getStudentTaskTrack();
    this.getStudentBadges();
  },
};
</script>
<style scoped>
.badge-label, .badge-labelday {
  position: absolute;
  margin-left: 1%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.badge-label {
  margin-top: 3%;
}

.badge-labelday {
  margin-top: -2%;
}

.badge-number, .badge-title {
  color: #fff;
  font-weight: bold;
  display: block;
}

.badge-number {
  font-size: 24px;
}

.badge-title {
  font-size: 18px;
  text-transform: uppercase;
}

.overlay, .overlay1 {
  position: absolute;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  margin-left: 4%;
}

.overlay {
  top: 64%;
}

.overlay1 {
  top: 60%;
}

.badge-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to top right, #87a7f4, #843ac5);
  padding: 20px;
  border-radius: 15px;
  width: 60%;
  height: 475px;
  position: relative;
  margin-top: 2%;
}

.badge-row {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
  flex-wrap: wrap;
  gap: 10px;
}

.group-image-container {
  display: flex;
  justify-content: center;
  margin-top: -21px;
}

.group-image-container img {
  width: 95%;
  height: auto;
}

.badge {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-left: 30px;
  background: none;
}

.badge img {
  width: 130%;
  height: auto;
  border-radius: 10px;
}

.badge-shelf {
  position: absolute;
  bottom: 0;
  width: 80%;
  height: 10px;
  background-color: #824c24;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.tasks-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.task-box {
  background-color: #f8f9fc;
  border: 1px solid #d1d5de;
  border-radius: 8px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
 flex-grow: 1;
  flex-basis: 30%;
  max-width: 30%;
  margin-bottom: 15px;
  justify-content: center;
}

.task-box span {
  margin-left: 5px;
}

.task-box.completed {
  background-color: #dff5e0;
  border-color: #b2e3b5;
  color: #3c763d;
}

.heading {
  font-size: 25px;
  font-family: Roboto Slab;
  font-weight: 600;
  color: #2c1963;
  display: flex;
}

.hLine {
  background-color: #2c1963;
  width: 50px;
  height: 4px;
  border-radius: 20px;
  margin-left: 15px;
}

.header {
  position: absolute;
  top: -20px;
  background-color: #d31e48;
  padding: 5px 15px;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  width: 30%;
  font-size: 19px;
  text-align: center;
}

/* Responsive Adjustments */
@media (min-width: 590px) and (max-width: 1900px) {
  .badge { 
    width: 80px; /* Adjusted for small laptops */
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-left: 20px; /* Adjusted margin */
    background: none;
  }
  .badge-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to top right, #87a7f4, #843ac5);
  padding: 20px;
  border-radius: 15px;
  width: 75%;
  height: 475px;
  position: relative;
  margin-top: 2%;
}
}
@media only screen and (max-width: 600px) {
  .badge-container {
    width: 100%;
    height: auto;
    padding: 10px;
    margin-top: 7%;
  }

  .badge {
    width: 100px;
    height: 100px;
    margin-left:-19px;
  }
 

  .badge img {
    width: 100%;
  }

  .tasks-container {
    width: 100%;
  }

  .heading {
    font-size: 20px;
  }

  .hLine {
    width: 30px;
    height: 3px;
    margin-top: 15px;
  }

  .playImg {
    position: absolute;
    right: 35%;
    margin-top: 140px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
  .badge-container {
    width: 80%;
    margin-top: 2%;
  }

  .task-box {
    width: 45%;
    flex-grow: 1;
  flex-basis: 30%;
  max-width: 30%;
  margin-bottom: 15px;
  }
}
@media only screen and (max-width: 600px) {
  .heading {
    font-size: 20px;
  }
  .task-box {
    width: 100%;
    font-size:10px;
  }
  .badge-container {
    width: 102%;
  }
  .badge {
    width: 80px;
    height: 80px;
  }
  .header {
  position: absolute;
  top: -20px;
  background-color: #d31e48;
  padding: 5px 15px;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  width: 30%;
  font-size: 10px;
  text-align: center;
}
.badge-number {
  font-size: 10px;
}

}
</style>
